/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import fileManager from '../views/fileManager.vue'
import CompaniesList from '../views/CompaniesList.vue'
import AddCompany from "../views/AddCompany.vue"
import EditCompany from "../views/EditCompany.vue"
import ClientsList from "../views/ClientsList.vue"
import addClient from "../views/addClient.vue"
import EditClient from "../views/EditClient.vue"
import UsersList from "../views/UsersList.vue"
import AddCompanyUsers from "../../recycleBin/AddUserOld.vue"
import userForm from "../components/userForm.vue"
import LoginPage from "../views/LoginPage.vue"
import companyManagerMenu from "../views/companyManagerMenu.vue"
import toolBar from "../components/toolBar.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/login/:status',
    name: 'login',
    component: LoginPage,
    props: true
  },
  {
    path: '/CompaniesList',
    name: 'CompaniesList',
    component: CompaniesList
  },
  {
    path: '/companiesList/:action',
    name: 'CompaniesList',
    component: CompaniesList,
    props: true
  },
  {
    path: '/clientsList',
    name: 'ClientsList',
    component: ClientsList
  },
  {
    path: '/ClientsList/:action',
    name: 'ClientsList',
    component: ClientsList,
    props: true
  },
  {
    path: '/addCompany',
    name: 'addCompany',
    component: AddCompany,
  },
  {
    path: '/editCompany/:id',
    name: 'editCompany',
    component: EditCompany,
    props: true
  },
  {
    path: '/addCLient',
    name: 'addClient',
    component: addClient
  },
  {
    path: '/editClient/:id',
    name: 'editClient',
    component: EditClient,
    props: true
  },
  {
    path: '/addUser',
    name: 'addUser',
    component: userForm,
  },
  {
    path: '/UsersList/:action',
    name: 'UsersList',
    component: UsersList,
    props: true
  },
  {
    path: '/UsersList',
    name: 'UsersList',
    component: UsersList,
    props: true
  },
  {
    path: "/editUser/:id",
    name: 'editUser',
    component: userForm
  },
  {
    path: "/companyManagerMenu",
    name: 'companyManagerMenu',
    component: companyManagerMenu
  },
  {
   path: '/toolBar',
   name: 'toolBar',
   component: toolBar
  },
  {
    path: '/folders',
    name: 'folders',
    component: fileManager
  }




]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
