<template>
  <v-card
    class="mx-auto"
    height="400"
    width="256"
  >
    <v-navigation-drawer
      permanent
      class="elovation-3"
      overlay-color="indigo"
    >
      <v-list class="mt-4">
      <v-list-item-title class="text-center">{{menu}}</v-list-item-title>
                         <v-divider></v-divider>

        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
        >

           <router-link :to="item.link"   style="text-decoration: none;"
> 
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
           </router-link>

        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
import myjson from "@/data/he.js"
let json = JSON.parse(myjson);
  export default {
    data(){
        return {
            logOutLabel: json['log out'],
            menu: json['menu']
        }
    },
props: ['items'],

  }
</script>