<template>
  <div>
    <toolBar :items="administraitorMenu"></toolBar>
    <viewDataInnerJoin
      :addMessage="addMessage"
      :message="message"
      :columns="columns"
      table1="cm"
      tableInnerJoin="cm-m"
      :columnsToSelect="columnsToSelect"
      :onToSelect="onToSelect"
      add="/addCompany"
      name="companies"
      editPage="editCompany"
    ></viewDataInnerJoin>
  </div>
</template>
<script>
import { administraitorMenu } from "@/data/menus.js";
import { AllowAccesToThisPage } from "@/data/functions.js";
import toolBar from "@/components/toolBar.vue";
// import { AllowAccesToPage } from "@/contact-js/allowAccesPage.js";
// import { AllowAccesToThisPage } from "@/data/functions.js";
import myjson from "../data/he.js";
let json = JSON.parse(myjson);
import viewDataInnerJoin from "@/components/viewDataInnerJoin.vue";
export default {
  components: {
    viewDataInnerJoin,
    toolBar,
  },
  data() {
    return {
      administraitorMenu,
      pages: [],
      addMessage: json["add company"],
      message: json["companies"],
      columns: [
        {
          value: "company_name",
          text: json["company name"],
        },
        {
          value: "phone1",
          text: `${json["phone"]} 1`,
        },
        {
          value: "phone2",
          text: `${json["phone"]} 2`,
        },
        {
          value: "company_number",
          text: json["company number"],
        },
        {
          value: "address",
          text: json["adress"],
        },
        {
          value: "company_remarks",
          text: json["remarks"],
        },
        {
          value: "isAviable",
          text: json["status"],
        },
        {
          value: "manager_name",
          text: json["manager name"],
        },
        {
          value: "tz",
          text: json["tz"],
        },
        {
          value: "phone",
          text: json["phone"],
        },
        {
          value: "email",
          text: json["email"],
        },
        {
          value: "managerRemarks",
          text: json["remarks"],
        },
      ],
      columnsToSelect:
        "company_name,phone1,phone2,company_number,address,company_remarks,isAviable,manager_name,tz,phone,email,manager_remarks,companies.id",
      onToSelect: "companies.managerTz=companies_manager.tz",
    };
  },

  created() {
AllowAccesToThisPage(this.$route.name);
},
  methods: {
    // AllowAccesToThisPage: async function() {
    //   let pages = await AllowAccesToPage(localStorage.jwt);
    //   if (Object.prototype.hasOwnProperty.call(pages, "error")) {
    //     this.$router.push("/");
    //   } else if (Object.prototype.hasOwnProperty.call(pages, "pages")) {
    //     console.log(this.$route.name);
    //     if (!pages.pages.includes(this.$route.name)) {
    //       this.$router.push("/");
    //     }
    //   } else {
    //     this.$router.push("/");
    //   }
    // },
  }
}
</script>

