import { urlOfRootApi } from '@/data/const.js';
export async function insertToDB(table, values, t) {
let jsonObj={};
jsonObj.ab=table;
jsonObj.al=values;
 jsonObj.t=t;
 jsonObj=JSON.stringify(jsonObj);   
    const res = await fetch(`${urlOfRootApi}/DBConnection/insert.php`, {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `details=${jsonObj}`
    })
    if (!res.ok) {
        return { error: res.status };
    }
    else {
        let data = await res.json();
        console.log(data);
        if (Object.prototype.hasOwnProperty.call(data, 'error')) {
            return { error: data.error };
        }
        else if (Object.prototype.hasOwnProperty.call(data, 'success')) {
            return { success: 'data inserted' };
        }
        else {
            return { error: data };
        }
    }

}