<template>

 <v-btn 
 rounded
        @click="savef" 
        color="indigo darken-4"
        class="white--text"
large
        >
  {{ message }}
  </v-btn>
</template>
<script>
import myjson from "../data/he.js"
import { bus } from "../main.js"
let json = JSON.parse(myjson);
export default {
  props: {
    message: {
      default: json['save'],
      type: String
    }
  },
  methods: {
    savef: function () {
      console.log('save clicked');
      bus.$emit('saveclicked');
    }
  },
}
</script>

