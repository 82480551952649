export const validEmail = function (email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};
export const validMobilePhone = function (mobilePhone) {
    var re = /^[0][5][0|2|3|4|5|9|8|6]{1}[0-9]{7}$/g;
    return re.test(mobilePhone);
};
export const validPhone = function (phone) {
    var re = /[0][1-9]{1,2}[0-9]{7}/;
    return re.test(phone);
};
export const validNorequiredPhone= function(phone){
    if (phone === "") {
        return true;
    }
    else {
        return (validPhone(phone));
    }
}
export const validNumbers = function (number) {
    var re = /[0-9]/g;
    return re.test(number);
};
export const validOnlyLetters = function (mySrting){
    var re =  /^[a-zA-Zא-ת ]+$/g;
    return re.test(mySrting);
}
export const required = function (myVal) {
var re = /^[ ]+$/g;
if (myVal===""){
    return false;
}
return !re.test(myVal);
}
