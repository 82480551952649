<template>
  <div>
    <editTwoInputs  :input1="clientInputs" :input2="clientManagerInputs" formName="clients"
      :title="title" :menu="companyManagerMenu"></editTwoInputs>
  </div>
</template>
<script>
import { companyManagerMenu } from "@/data/menus.js"
import editTwoInputs from "@/components/editTwoInputs.vue"
import { clientInputs } from "@/data/inputsDetails"
import { clientManagerInputs } from "@/data/inputsDetails"
import myjson from "../data/he.js"
let json = JSON.parse(myjson);
export default {
  components: {
    editTwoInputs,
  },
  data() {
    return {
      companyManagerMenu,
      clientInputs,
      clientManagerInputs,
      title: json['edit client']
    }
  },

}

</script>

