<template>
  <v-row class="d-flex justify-center mx-6 py-5">
    <v-col xs="12" md="8" lg="6" class="elevation-3 text-center">
      <MySnackBar></MySnackBar>
      <myH1 :message="message"></myH1>
      <inputs inputs-name="users" :inputs-value="userInputs"></inputs>
      <isAviable class="mt-5"></isAviable>
      <!-- <v-row class="mr-3"> -->
      <!-- <v-col lg="5" xs="12" md="6" class="elevation-3" align-self="center"> -->
      <v-divider></v-divider>
      <checkBoxes :values="permissionsData" checkBoxesName="permissions" class="my-3 mr-3"></checkBoxes>
      <!-- </v-col> -->
      <!-- </v-row> -->
      <v-divider></v-divider>
      <h2 class="mt-3 indigo--text text-right mr-3">{{ permissionsForCompany }}</h2>
      <!-- <v-row class="mr-3"> -->
      <!-- <v-col lg="5" xs="12" md="6" class="elevation-3" align-self="center"> -->
      <checkBoxes v-if="companiesData" class="overflow-y-auto mr-3" style="height:350px ;" :values="companiesData"
        checkBoxesName="companies"></checkBoxes>
      <!-- </v-responsive> -->
      <!-- </v-col> -->
      <!-- </v-row> -->
      <saveButton></saveButton>
    </v-col>
  </v-row>
</template> 
<script>
/* eslint-disable */
import { errorHandlingDatabaseErrors } from "@/data/functions.js"
import { AllowAccesToThisPage } from "@/data/functions";
import { userInputs } from "@/data/inputsDetails.js"
import Vue from "vue";
import { insertToDB } from "@/contact-js/insert.js";
import { selectFromDB } from "@/contact-js/select.js";
import myH1 from "@/components/myH1.vue";
import inputs from "@/components/myInputs.vue";
import isAviable from "@/components/isAviable.vue";
import saveButton from "@/components/saveButton.vue";
import myjson from "@/data/he.js";
import MySnackBar from "@/components/mySnackBar.vue";
import { bus } from "@/main.js";
import checkBoxes from "@/components/checkBoxes.vue";
let json = JSON.parse(myjson);
export default {
  components: {
    myH1,
    inputs,
    isAviable,
    saveButton,
    checkBoxes,
    MySnackBar
  },

  data() {
    return {
      userInputs,
      permissionsToUser: {},
      companiesPermissions: {},
      succes: json["succes insert data"],
      companyPermissionsCount: 0,
      permissionsCount: 0,
      message: json["add/edit user"],
      error: json["error insert data"],
      permissionsValues: [],
      permissionsData: "",
      companiesValues: [],
      companiesValuesSaved: false,
      permissionsValuesSaved: false,
      companiesData: null,
      permissionsForCompany: json["permissions for company"],
      userValues: "",
      userValuesSaved: false,
      isAviabledSaved: false,

    };
  },
  computed: {
    isAllTrue: function () {
      return (
        this.userValuesSaved &&
        this.permisionsValuesSaved &&
        this.companiesValuesSaved &&
        this.isAviabledSave
      );
    },
  },
  watch: {
    isAllTrue(val) {
      if (val) {
        //insert user to DB
        insertToDB("cm-u", this.userValues, "user");
      }
    },
  },
  created() {
    console.log('i am here');
//     AllowAccesToThisPage(this.$route.name);
//     console.log('i allow acces to this page');
// this.selectDataFromDB();
    bus.$on("dataExported", (data, name) => {
      console.log(data);
      if (data != "null") {
        if (name == "permissions") {
          this.permissionsData = JSON.parse(data);
          for (let checkBoxesValue of this.permissionsData) {
            Vue.set(
              checkBoxesValue,
              "label",
              json[checkBoxesValue.description]
            );
          }
        }
        if (name == "companies") {
          this.companiesData = JSON.parse(data);
          for (let a of this.companiesData) {
            Vue.set(a, "label", a.company_name);
          }
        }
      }
    });
    bus.$on("exportValuesInputs", (name, values) => {
      this.userValues = values;
      this.userValuesSaved = true;
    });
    bus.$on("checkBoxesExported", (name, values) => {
      if (name == "permissions") {
        this.permissionsValues = values;
        this.permisionsValuesSaved = true;
      } else {
        if (name == "companies") {
          this.companiesValues = values;
          this.companiesValuesSaved = true;
        }
      }
    });
    bus.$on("exportIsAviable", (values) => {
      if (!this.isAviabledSave) {
        Vue.set(this.userValues, "date", new Date(values.date));
        Vue.set(this.userValues, "isAviable", values.isAviable);
        this.isAviabledSave = true;
      } else {
        this.userValues.date = new Date(values.date);
        this.userValues.isAviable = values.isAviable;
      }
    });
    bus.$on("dataInserted", (data, inputsName) => {
      if (data != "1") {
        this.userValuesSaved = false;
        if (data == "error") {
          alert(this.error);
        } else if (data.includes("Duplicate")) {
          alert(json["duplicate error"]);
        }
      } else {
        if (inputsName == "user") {
          //insret permissions to DB
          //creat the object of permissions
          for (let i in this.permissionsValues) {
            if (this.permissionsValues[i] == true) {
              this.permissionsToUser[i] = {};
              Vue.set(
                this.permissionsToUser[i],
                "company_user",
                this.userValues.phone
              );
              Vue.set(this.permissionsToUser[i], "permissions", i);
            }
          }
          if (Object.keys(this.permissionsToUser).length > 0) {
            for (let a in this.permissionsToUser) {
              insertToDB("p-t-cm-u", this.permissionsToUser[a], "permissions");
            }
          } else {
            this.addCompanyPermissions(this.companiesPremissions);
          }
        } else if (inputsName == "permissions") {
          this.permissionsCount++;
          if (
            this.permissionsCount == Object.keys(this.permissionsToUser).length
          ) {
            this.addCompanyPermissions(this.companiesPermissions);
          }
        } else if (inputsName == "companyPermissions") {
          this.companyPermissionsCount++;
          if (
            this.companyPermissionsCount ==
            Object.keys(this.companiesPermissions).length
          )
            alert(this.succes);
        }
      }
    });
  },
  methods: {
    async selectDataFromDB() {
      let data = await selectFromDB("cm", "id,company_name", 1, localStorage.jwt);
      if (Object.prototype.hasOwnProperty.call(data, 'error')) {
        this.errorHandlingDatabaseErrors(data);
      }
      else if (Object.prototype.hasOwnProperty.call(data, 'success')) {
        this.companiesData = data.succes;
        for (let a of this.companiesData) {
          Vue.set(a, "label", a.company_name);
        }
      }
    },
        errorHandlingDatabaseErrors: errorHandlingDatabaseErrors,
    addCompanyPermissions: function () {
      //create the company permissions object
      for (let i in this.companiesValues) {
        if (this.companiesValues[i] == true) {
          this.companiesPermissions[i] = {};
          Vue.set(this.companiesPermissions[i], "company", i);
          Vue.set(this.companiesPermissions[i], "user", this.userValues.phone);
        }
      }
      if (Object.keys(this.companiesPermissions).length > 0) {
        for (let a in this.companiesPermissions) {
          insertToDB(
            "cm-t-u",
            this.companiesPermissions[a],
            "companyPermissions"
          );
        }
      } else {
        alert(this.succes);
      }
    },
  },
};
</script>



   