<!-- eslint-disable -->
<template>
  <div>
    <mySnackBar ref="alert"></mySnackBar>
    <v-data-table :headers="columns" :items="dataToView" :items-per-page="10" :sort-by="columns[0].value"
      class="elevation-3 px-6 py-3" :header-props="{
        sortIcon: 'mdi-sort-descending',
      }">
      <template v-for="checkBox in checkBoxes" v-slot:[`item.${checkBox}`]="{ item }">
        <v-simple-checkbox v-model="item[checkBox]" :key="checkBox" disabled></v-simple-checkbox>
      </template>
      <template v-for="list in lists" v-slot:[`item.${list}`]="{ item }">
        <listInTable :key="list" :list="item[list]" :panelHeader="companyPermissions"></listInTable>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ message }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <router-link  v-if="add!=''" :to="add" style="display: inline-block; text-decoration: none">
            <v-btn class="mx-2" rounded dark color="indigo  darken-4" large>
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </router-link>
          <v-dialog v-if="dialogFields.length>0">
          </v-dialog>

        </v-toolbar>
      </template>
      <template v-if="editPage != ''" v-slot:[`item.action`]="{ item }">
        <router-link :to="`/${editPage}/${findKey(item)}`" style="display: inline-block; text-decoration: none">
          <v-icon small class="mr-2"> mdi-pencil </v-icon>
        </router-link>
      </template>
    </v-data-table>
  </div>
</template>
<script>
/* eslint-disable */
import Vue from "vue";
import { bus } from "../main.js";
import myjson from "@/data/he.js";
let json = JSON.parse(myjson);
import expandPanelWhithList from "./listInTable.vue";
import mySnackBar from "@/components/mySnackBar.vue";
import listInTable from "./listInTable.vue";
export default {
  components: { mySnackBar, expandPanelWhithList, listInTable },
  props: {
    addMessage: String,
    message: String,
    columns: Array,
    editPage: {
      type: String,
      default: ''
    },
    add: {
      type: String,
      default: ''
    },
    dialogFields: {
      type: Array,
      default(){
        return [];
      } 
    },
    name: String,
    checkBoxes: Array,
    data: Array,
    lists: Array,
  },
  data() {
    return {
      myColumns: this.columns,
      success: json["succes insert data"],
      companyPermissions: json["company permissions"],
    };
  },
  methods: {
    changeCheckboxesToString(){
      for(let i in this.data){
        for(let j in this.checkBoxes){
          console.log(typeof(this.data[i][this.checkBoxes[j]]));
          if (typeof(this.data[i][this.checkBoxes[j]])==='string')
          {
            this.data[i][this.checkBoxes[j]]=parseInt(this.data[i][this.checkBoxes[j]]);
            console.log(typeof(this.data[i][this.checkBoxes[j]]));

          }
        }
      }
    },
    chagetoLowerCase: function (data) {
      let lowerCaseData = [];
      for (let i in data) {
        lowerCaseData[i] = {};
        for (let j in data[i]) {
          Vue.set(
            lowerCaseData[i],
            `${j.charAt(0).toLowerCase()}${j.slice(1)}`,
            data[i][j]
          );
        }
      }
      return lowerCaseData;
    },
    findKey(item) {
      return this.dataToView.indexOf(item);
    },
    removeParamrsFromUrl() {
      this.$router.push(`/${this.$router.currentRoute.name}`);
    },
  },
  computed: {
    dataToView() {
      if (this.data != null) {
        let temp = this.data.slice(0);
        for (let i in temp) {
          delete temp[i]["id"];
        }
        this.changeCheckboxesToString();
        this.chagetoLowerCase(temp);
        return temp;
      }
    }
  },
  created() {
    //add action to columns
    // if (this.editPage != '') {
      this.myColumns.push({ value: "action", text: "", width: "30px" });
    // }
  },
  mounted() {
    if (this.$route.params.action === "inserted") {
      this.$refs.alert.alertNow('success', json['succes insert data']);
      setTimeout(this.removeParamrsFromUrl(), 10000);
    }
    else if (this.$route.params.action === "updated") {
      this.$refs.alert.alertNow("success", json["Data updated"]);
      setTimeout(this.removeParamrsFromUrl(), 10000);
    }
  },
};
</script>

