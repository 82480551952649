/*eslint-disable*/
// import { required } from "@/components/validation";
import { validPhone } from "@/components/validation";
import { validEmail } from "@/components/validation";
import { validMobilePhone } from "@/components/validation";
import { validNorequiredPhone } from "@/components/validation";
import { validNumbers } from "@/components/validation";
import { validOnlyLetters } from "@/components/validation";
import myJson from "@/data/he.js"
let json = JSON.parse(myJson);
export const companyInputs = {
  values: [
    {
      placeHolder: json['company name'],
      type: 'text',
      id: 'company_name',
      valid: ['required'],
      rule: [value => !!value || `${json['required']}`]
    },
    {
      placeHolder: `${json['phone']} 1`,
      type: 'text',
      id: 'phone1',
      valid: ['phone','required'],
      rule: [value => !!value || `${json['required']}`,
      value => (validPhone(value)) || `${json['not valid phone']}`]
    },
    {
      placeHolder: `${json['phone']} 2`,
      type: 'text',
      id: 'phone2',
      valid: ['phone'],
      rule: [value => {
        if (!value)
          return true;
        if (validPhone(value))
          return true;
        return `${json['not valid phone']}`;
      }]
    },
    {
      placeHolder: json['company number'],
      type: 'text',
      id: 'company_number',
      valid: ['numbers','required'],
      rule: [value => !!value || `${json['required']}`,
      value => (validNumbers(value)) || `${json['not valid company number']}`]
    },
    {
      placeHolder: json['remarks'],
      type: 'text',
      id: 'company_remarks',
      valid: [],
      rule: []
    }
  ],
  name: 'company',
  table: 'cm',
  duplicateError: json['duplicalte company number error'],
  message: json['company detailes'],
  forgienKey: 'managerTz',
  defaultValues: {},
  primary: 'company_number',
  homePage: 'CompaniesList'
};
export const companyManagerInputs = {
  values: [
    {
      placeHolder: json['full name'],
      type: 'text',
      id: 'manager_name',
      valid: ['required'],
      rule: [value => !!value || `${json['required']}`]
    },
    {
      placeHolder: json['tz'],
      type: 'text',
      id: 'tz',
      valid: ['numbers','required'],
      rule: [value => !!value || `${json['required']}`,
      value => (validNumbers(value)) || `${json['not valid tz']}`]
    },
    {
      placeHolder: json['phone'],
      type: 'tel',
      id: 'phone',
      valid: ['mobilePhone','required'],
      rule: [value => !!value || `${json['required']}`,
      value => (validMobilePhone(value)) || `${json['not valid phone']}`]
    },
    {
      placeHolder: json['mail'],
      type: 'email',
      id: 'email',
      valid: ['email','required'],
      rule: [value => !!value || `${json['required']}`,
      value => (validEmail(value)) || `${json['not valid email']}`]
    },
    {
      placeHolder: json['remarks'],
      type: 'text',
      id: 'manager_remarks',
      valid: [],
      rule: []
    }
  ],
  name: 'manager',
  table: 'cm-m',
  duplicateError: json['duplicate manager error'],
  message: json['copmany manager details'],
  primary: 'tz',
  defaultValues: {}
}
export const clientInputs = {
  values:
    [
      {
        placeHolder: json['client name'],
        type: 'text',
        id: 'name',
        valid: ['required'],
        rule: [
          value => !!value || `${json['required']}`
        ],
      },
      {
        placeHolder: `${json['phone']} 1`,
        type: 'tel',
        id: 'phone1',
        valid: ['phone', 'required'],
        rule: [
          value => !!value || `${json['required']}`,
          value => (validPhone(value)) || `${json['not valid phone']}`,
        ]
      },
      {
        placeHolder: `${json['phone']} 2`,
        type: 'tel',
        id: 'phone2',
        valid: ['phone'],
        rule: [
          value => {
            if (!value) return true;
            if (validNorequiredPhone(value))
              return true;
            return `${json['not valid phone']}`;
          }
        ]
      },
      {
        placeHolder: json['company number'],
        type: 'text',
        id: 'company_number',
        valid: ['numbers','required'],
        rule: [
          value => !!value || `${json['required']}`,
          values => (validNumbers(values)) || `${json['not valid company number']}`
        ]
      },
      {
        placeHolder: json['adress'],
        type: 'text',
        id: 'address',
        valid: ['required'],
        rule: [value => !!value || `${json['required']}`]
      },
      {
        placeHolder: json['remarks'],
        type: 'text',
        id: 'client_remarks',
        valid: [],
        rule: []
      }
    ],
  name: 'client',
  table: 'cl',
  duplicateError: json['duplicalte company number error'],
  message: json['client details'],
  forgienKey: 'managerPhone',
  defaultValues: {},
  primary: 'company_number',
  homePage: 'ClientsList'
};

export const clientManagerInputs = {
  values: [
    {
      placeHolder: json['full name'],
      type: 'text',
      id: 'manager_name',
      valid: ['required'],
      rule: [value => !!value || `${json['required']}`,
      value => (validOnlyLetters(value)) || `${json['not valid name']}`]

    },
    {
      placeHolder: json['phone'],
      type: 'tel',
      id: 'phone',
      valid: ['mobilePhone','required'],
      rule: [value => !!value || `${json['required']}`,
      value => (validMobilePhone(value)) || `${json['not valid phone']}`]
    },
    {
      placeHolder: json['remarks'],
      type: 'text',
      id: 'manager_remarks',
      valid: [],
      rule: []
    }
  ],
  name: 'manager',
  primary: 'phone',
  table: 'cl-m',
  duplicateError: json['duplicate phone error'],
  message: json['manager details'],
  defaultValues: {}
}
export const userInputs = [
  {
    placeHolder: json["first name"],
    type: "text",
    id: "firstname",
    valid: ['required'],
    rule: [value => !!value || `${json['required']}`]
  },
  {
    placeHolder: json["last name"],
    type: "text",
    id: "lastname",
    valid: ['required'],
    rule: [value => !!value || `${json['required']}`]
  },
  {
    placeHolder: json["phone"],
    type: "tel",
    id: "phone",
    valid: ['mobilePhone','required'],
    rule: [value => !!value || `${json['required']}`,
    value => (validMobilePhone(value)) || `${json['not valid phone']}`]
  },
  {
    placeHolder: json["remarks"],
    type: "text",
    id: "remarks",
    valid: [],
  },
]

