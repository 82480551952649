<template>
  <div>
    <TableOfData :add="add" :addMessage="addMessage" :columns="columns" :data="myData" :editPage="editPage"
      :message="message" :name="name" :checkBoxes="checkBoxes">
    </TableOfData>
  </div>
</template>
<script>
/* eslint-disable */
import Vue from "vue";
import { selectInnerJoin } from "../contact-js/selectInnerJoin.js";
import { bus } from "../main.js";
import myjson from "@/data/he.js";
let json = JSON.parse(myjson);
import mySnackBar from "@/components/mySnackBar.vue";
import TableOfData from "./tableOfData.vue";
export default {
  components: { mySnackBar, TableOfData },
  props: [
    "addMessage",
    "message",
    "columns",
    "table1",
    "tableInnerJoin",
    "columnsToSelect",
    "onToSelect",
    "editPage",
    "add",
    "name",
  ],
  data() {
    return {
      myData: [],
      myColumns: [],
      success: json["succes insert data"],
      checkBoxes: ['isAviable']
    };
  },
  methods: {
    chagetoLowerCase: function (data) {
      let lowerCaseData = [];
      for (let i in data) {
        lowerCaseData[i] = {};
        for (let j in data[i]) {
          Vue.set(
            lowerCaseData[i],
            `${j.charAt(0).toLowerCase()}${j.slice(1)}`,
            data[i][j]
          );
        }
      }
      return lowerCaseData;
    },
    removeParamrsFromUrl() {
      this.$router.push(`/${this.$router.currentRoute.name}`);
    },
  },
  async created() {
    this.myData = await selectInnerJoin(
      this.table1,
      this.tableInnerJoin,
      this.columnsToSelect,
      this.onToSelect,
      this.name,
      localStorage.jwt
    );
    if (this.myData.hasOwnProperty('error')) {
      if (this.myData.error === 'Wrong number of segments') {
        this.$router.push("/login/loginFailed");
      }
    }
    else if (this.myData.hasOwnProperty('success')) {
      this.myData = this.myData.success;
      this.myData= this.chagetoLowerCase(this.myData);
      this.$dataFromServer[this.name] = JSON.parse(JSON.stringify(this.myData));
    }
    else {
      this.$refs.alert.alertNow('error',this.myData);
    }
  }
,
mounted() {
  if (this.$route.params.action === "inserted") {
    bus.$emit("alert", "success", this.success);
    setTimeout(this.removeParamrsFromUrl(), 10000);
  } else if (this.$route.params.action === "updated") {
    bus.$emit("alert", "success", json["Data updated"]);
    setTimeout(this.removeParamrsFromUrl(), 10000);
  }
},
};
</script>

