<template>
    <div>
        <twoInputsForm
            :input1="clientInputs"
            :input2="clientManagerInputs"
            :saveMessage="saveMessage"
            formType="insert"
            :title="title"
            :menu="companyManagerMenu"
        ></twoInputsForm>
    </div>
</template>
<script>
import { companyManagerMenu } from "@/data/menus.js";
import { AllowAccesToThisPage } from "@/data/functions.js";
// import toolBar from "@/components/toolBar.vue";
import myjson from "../data/he.js"
import { clientInputs } from "@/data/inputsDetails.js";
import { clientManagerInputs } from "@/data/inputsDetails.js";
import twoInputsForm from "../components/twoInputsForm.vue"
let json = JSON.parse(myjson);
export default {
    components: {
        twoInputsForm,
        // toolBar
    },
    data() {
        return {
            companyManagerMenu,
            saveMessage: json['save'],
            clientManager: json['manager details'],
            clientInputs,
            clientManagerInputs,
            title: json['add client']
        }
    },
    beforeCreate(){
        AllowAccesToThisPage(this.$route.name);
    }
}
</script>