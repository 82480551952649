<template>
  <div>
    <toolBar :items="companyManagerMenu"></toolBar>
    <v-row class="d-flex justify-center mx-6 py-5">
      <v-col xs="12" md="8" lg="6" class="elevation-3 text-center">
        <MySnackBar ref="alert"></MySnackBar>
        <myH1 :message="message"></myH1>
        <inputs inputs-name="users" :inputs-value="userInputs" :defaultValues="defaultValues || undefined"
          :formType="formType" @exportValuesInputs="saveValues"></inputs>
        <isAviable class="mt-5" :defaultValue="defaultValues.isAviable" @exportIsAviable="isAviableExported">
        </isAviable>
        <v-divider></v-divider>
        <checkBoxes :values="permissionsData" checkBoxesName="permissions" class="my-3 mr-3"
          :defaultValues="defaultPermissions" :formType="formType" @checkBoxesExported="saveCheckBoxesValues">
        </checkBoxes>
        <v-divider></v-divider>
        <h2 class="mt-3 indigo--text text-right mr-3">
          {{ permissionsForCompany }}
        </h2>
        <checkBoxes v-if="companiesData != undefiened" class="overflow-y-auto mr-3" style="max-height: 350px"
          :values="companiesData" checkBoxesName="companies" :defaultValues="defaultCompanies || undefiened"
          @checkBoxesExported="saveCheckBoxesValues"></checkBoxes>
        <saveButton></saveButton>
      </v-col>
    </v-row>
  </div>
</template> 
<script>
/* eslint-disable */
import { companyManagerMenu } from "@/data/menus.js";
import toolBar from "./toolBar.vue";
import { AllowAccesToThisPage, arrayToInsertToDB, createObjectFieldInTable, errorHandlingDatabaseErrors } from "@/data/functions";
import { addIsAviableToValues } from "@/data/functions";
import { update } from "@/contact-js/update.js";
import { userInputs } from "@/data/inputsDetails.js";
import Vue from "vue";
import { insertToDB } from "@/contact-js/insert.js";
import { selectFromDB } from "@/contact-js/select.js";
import myH1 from "@/components/myH1.vue";
import inputs from "@/components/myInputs.vue";
import isAviable from "@/components/isAviable.vue";
import saveButton from "@/components/saveButton.vue";
import myjson from "@/data/he.js";
import MySnackBar from "@/components/mySnackBar.vue";
import { bus } from "@/main.js";
import checkBoxes from "@/components/checkBoxes.vue";
import { deletFromDB } from "@/contact-js/delet";
let json = JSON.parse(myjson);
export default {
  components: {
    myH1,
    inputs,
    isAviable,
    saveButton,
    checkBoxes,
    MySnackBar,
    toolBar
  },

  data() {
    return {
      //for update form if all this variable is false There is no change in the data
      userChanged: false,
      isAviableChanged: false,
      permissionsChanged: false,
      companiesChanged: false,
      //for update form for check if the update success
      userInputsUpdated: false,

      companyManagerMenu,

      userInputs,
      companiesPermissions: {},

      companyPermissionsCount: 0,

      message: json["add/edit user"],
      error: json["error insert data"],
      succes: json["succes insert data"],
      permissionsForCompany: json["permissions for company"],

      permissionsValues: [],
      permissionsData: [
        { id: "open_folders", label: json["open folders"] },
        { id: "edit_rename_folders", label: json["edit/rename folders"] },
        { id: "edit_clients", label: json["edit clients"] },
      ],
      companiesValues: [],

      //when all this variable are true all data come from the inside components
      companiesValuesSaved: false,
      permissionsValuesSaved: false,
      userValuesSaved: false,
      isAviabledSaved: false,

      companiesData: [],
      userValues: {},

      defaultValues: {},
      isInsertSucces: false,
    };
  },
  computed: {
    isAllTrue: function () {
      return (
        this.userValuesSaved &&
        this.permissionsValuesSaved &&
        this.companiesValuesSaved &&
        this.isAviabledSaved
      );
    },
    userId: function () {
      if (this.$route.params.hasOwnProperty("id")) {
        return this.$route.params.id;
      } else return null;
    },
    formType: function () {
      if (this.userId === null) return "insert";
      else return "update";
    },
    defaultPermissions: function () {
      if (this.formType === "insert") {
        return null;
      } else {
        let d = {};
        for (let i in this.permissionsData) {
          Vue.set(
            d,
            this.permissionsData[i].id,
            !!this.defaultValues[this.permissionsData[i].id]
          );
        }
        console.log(d);
        return d;
      }
    },
    defaultCompanies: function () {
      if (this.formType === "insert") {
        return null;
      } else {
        let d = {};
        let result = this.defaultValues.companiesDetailes.map(({ id }) => id);
        console.log(result);
        for (let i in this.companiesData) {
          let isCompany;

          if (result.indexOf(this.companiesData[i].id) != -1) {
            isCompany = 1;
          } else isCompany = 0;
          Vue.set(d, this.companiesData[i].id, isCompany);
        }
        return d;
      }
    },
  },
  watch: {
    //when all data of form comes from the components
    isAllTrue(val) {
      if (val) {
        this.dataEntryToDB();
      }
    }
  },
  created: function () {
    AllowAccesToThisPage(this.$route.name);
    this.selectCompaniesFromDB();
    //creat default values in update form
    if (this.formType === "update") {
      //insert the default value from server to default value object
      this.defaultValues = this.$dataFromServer["users"][this.userId];
    }
    //Listening to events

    //listen to event after data updated in DB and DB retrun response if the update was successful
    // bus.$on("dataUpdated", (data, inputName) => {
    // if (data == "1") {
    //   this.userInputsUpdated = true;
    // if (this.companiesChanged) {
    //       this.updateCompanies();
    //     }
    //   } else if (data.includes("Duplicate")) {
    //     bus.$emit("alert", "warning", json["duplicate error"]);
    //     this.userInputsUpdated = false;
    //   } else {
    //     this.userInputsUpdated = false;
    //   }
    // });
    //listen to event after data inserted to DB and the DB return a responase if the inserted was succesful
    //listen to event after data deleted from DB and DB  the database returned a response if the deletion was successful
    // bus.$on("dataDeleted", (data) => {
    //   if (data == 1) {
    //     this.companyPermissionsCount++;
    //   } else {
    //     bus.$emit("alert", "error", this.error);
    //   }
    // });
  },

  methods: {
    errorHandlingDatabaseErrors,
    async selectCompaniesFromDB() {
      let data = await selectFromDB("cm", "id,company_name", 1, localStorage.jwt);
      if (Object.prototype.hasOwnProperty.call(data, 'error')) {
        this.errorHandlingDatabaseErrors(data);
      }
      else if (Object.prototype.hasOwnProperty.call(data, 'success')) {
        this.companiesData = JSON.parse(data.success);
        console.log(this.companiesData);
        for (let a of this.companiesData) {
          Vue.set(a, "label", a.company_name);
        }
      }
    },
    saveValues(event) {
      if (this.formType === "update") {
        if (event.values != null) {
          this.userChanged = true;
        }
      }
      this.userValues = event.values;
      this.userValuesSaved = true;
    },
    isAviableExported(values) {
      if (this.formType === "update") {
        if (values != null) {
          this.userChanged = true;
          this.userInputsUpdated = false;
        }
      }
      this.userValues = { ...this.userValues, ...values };
      this.isAviabledSaved = true;
    },
    saveCheckBoxesValues(event) {
      if (this.formType === "insert") {
        if (event.name === "permissions") {
          if (this.userValues === null) {
            this.userValues = {};
          }
          console.log(event.values);
          this.userValues = Object.assign({ ...this.userValues, ...event.values });
          console.log(this.userValues);
          this.permissionsValuesSaved = true;
        } else {
          if (event.name === "companies") {
            this.companiesValues = event.values;
            this.companiesValuesSaved = true;
          }
        }
      } else {
        //update form
        if (event.name === "permissions") {
          this.permissionsValuesSaved = true;
          if (event.values != null) {
            this.userChanged = true;
            this.userValues = Object.assign({ ...this.userValues, ...event.values });
          }
        } else {
          this.companiesValuesSaved = true;
          if (event.values != null) {
            this.companiesChanged = true;
            this.companiesPermissions = event.values;
          }
        }
      }
    },
    updateCompanies: async function () {
      let isUpdateFailed = false;
      let phone;
      if (this.userValues.hasOwnProperty('phone')) {
        phone = this.userValues.phone.value;
      }
      else {
        phone = this.defaultValues.phone;
      }
      for (let i in this.companiesPermissions) {
        console.log(this.companiesPermissions[i]);
        let line;
        //insert new company
        if (this.companiesPermissions[i].value) {
            line = [new createObjectFieldInTable('company', i, ['required']),
            new createObjectFieldInTable('user', phone, ['mobilePhone', 'required'])];
            console.log(line);
            let result = await insertToDB("cm-t-u", line, localStorage.jwt);
            if (!result.hasOwnProperty('success')) {
               isUpdateFailed = true;
                this.$refs.alert.alertNow('error', json['error']);
                this.resetForm();
               break;
            }
        }
        else {
            let condition = `user=${phone} and company=${i}`;
            let result = await deletFromDB("cm-t-u", condition, localStorage.jwt);
            console.log(result);
            if (!result.hasOwnProperty('success')) {
              isUpdateFailed = true;
              this.$refs.alert.alertNow('error', json['error']);
              this.resetForm();
              break;
            }
          }
        }
        if (!isUpdateFailed) {
          this.$router.push('/UsersList/updated');
        }
      },

    async dataEntryToDB() {
        let result;
        if (this.formType === "insert") {
          result = await insertToDB("cm-u", this.userValues, localStorage.jwt);
          if (result.hasOwnProperty('error')) {
            this.resetForm();
            if (result.error.hasOwnProperty('errorInfo') && (result.error.errorInfo.includes(1062))) {
              this.$refs.alert.alertNow('error', json['duplicate error']);
            }
            else {
              this.errorHandlingDatabaseErrors(result);
            }
          }
          else if (result.hasOwnProperty('success')) {
            this.addCompanyPermissions();
          }
          else {
            this.$refs.alert.alertNow('error', json['error insert data']);
            this.resetForm();
          }
        }
        //update form
        else {
          //no changes
          if (!this.userChanged && !this.companiesChanged) {
            this.$refs.alert.alertNow('warning', json['no changes']);
          }
          else {
            //save the user values
            if (this.userChanged) {
              result = await update(
                "cm-u",
                `phone=${this.defaultValues["phone"]}`,
                this.userValues,
                localStorage.jwt
              );
              console.log(result);
              //duplicate error
              if (result.hasOwnProperty('error')) {
                this.resetForm();
                if (result.error === 'duplicate error') {
                  this.$refs.alert.alertNow('warning', json['duplicate error']);
                }
                else {
                  errorHandlingDatabaseErrors(result);
                }
              }
              else if (result.hasOwnProperty('success')) {
                this.updateCompanies();
              }
              else {
                errorHandlingDatabaseErrors(result);
              }
            } else if (this.companiesChanged) {
              this.updateCompanies();
            }
          }
        }
      },
      resetForm() {
        this.userValuesSaved = false;
        this.isAviabledSaved = false;
        this.companiesValuesSaved = false;
        this.permissionsValuesSaved = false;
      },
      setObjectsOfCompanies: function (myData) {
        for (let a of myData) {
          let company = {
            label: a.company_name,
            id: a.id.toString(),
          };
          this.companiesData.push(company);
        }
      },
      addCompanyPermissions: async function () {
        //create the company permissions object
        for (let i in this.companiesValues) {
          if (this.companiesValues[i].value === true) {
            this.companiesPermissions[i] = {};
            Vue.set(this.companiesPermissions[i], "company", i);
            Vue.set(this.companiesPermissions[i], "user", this.userValues.phone);
          }
        }
        if (Object.keys(this.companiesPermissions).length > 0) {
          let a;
          for (a in this.companiesPermissions) {
            this.companiesPermissions[a].company = new createObjectFieldInTable('company', this.companiesPermissions[a].company, ['required', 'numbers']);
            console.log(this.companiesPermissions[a].user.name);
            this.companiesPermissions[a].user.name = 'user';
            // this.companiesPermissions[a].user.name='user';
            let companyToDB = arrayToInsertToDB(this.companiesPermissions[a]);
            console.log(companyToDB);
            let result = await insertToDB(
              "cm-t-u",
              companyToDB,
              localStorage.jwt
            );
            console.log(`result of enter companies ${result}`);
            if (!result.hasOwnProperty('success')) {
              this.$refs.alert.alertNow('error', json['error insert data']);
              this.resetForm();
              this.FailedInserData = true;
              break;
            }
          }
          if (!this.FailedInserData) {
            this.$router.push('/UsersList/inserted');
          }
          else {
            this.$refs.alert.alertNow('error', json['error insert data']);
          }
        } else {
          this.$router.push('/UsersList/inserted');
        }
      },
    },
  };
</script>



   