<template>
  <v-row class="d-flex justify-center mx-6 py-5">
    <MySnackBar ref="alert"></MySnackBar>
    <v-col xs="12" md="12" sm="12" lg="6" class="elevation-3 text-center">
      <v-card>
        <v-card-title class="indigo darken-4 white--text">
          {{ enter }}
        </v-card-title>
        <v-card-text>
          <myInputs
            @exportValuesInputs="sendOtp($event)"
            inputsName="login"
            :inputsValue="inputsValue"
          ></myInputs>
          <saveButton class="my-3" :message="sendPassword"> </saveButton>
          <template v-if="sentPassword">
            <p>{{ insertOTP }}</p>
            <v-row align="center" justify="center">
              <v-col xs="10" md="10" sm="10" lg="8" class="text-center">
                <v-otp-input
                  :disabled="loading"
                  style="direction: ltr"
                  length="8"
                  v-model="OTPpassword"
                ></v-otp-input>
                <v-overlay absolute :value="loading">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-overlay>
                <v-btn @click="checkOtp()"> {{ ok }}</v-btn>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
/* eslint-disable */
// import { bus } from "@/main.js";
// import { otp } from "@/contact-js/otp.js";
import { urlOfRootApi } from "@/data/const.js";
import saveButton from "@/components/saveButton.vue";
import { validMobilePhone } from "@/components/validation.js";
import myInputs from "@/components/myInputs.vue";
import myjson from "../data/he.js";
import MySnackBar from "@/components/mySnackBar.vue";
import Vue from "vue";
let json = JSON.parse(myjson);
export default {
  components: {
    myInputs,
    saveButton,
    MySnackBar,
  },
  data() {
    return {
      enter: json["enter"],
      sendPassword: json["send password"],
      insertOTP: json["Enter the one-time password sent to you"],
      ok: json["ok"],
      notExistPhone: json["The phone number you entered is not exist"],

      sentPassword: false,
      loading: false,

      details: {},

      OTPpassword: "",
      inputsValue: [
        {
          placeHolder: json["phone"],
          type: "text",
          id: "phone",
          rule: [
            (value) => !!value || `${json["required"]}`,
            (value) => validMobilePhone(value) || `${json["not valid phone"]}`,
          ],
        },
      ],
    };
  },
mounted:function() {
  //alert if login failed
  console.log('i am here');
  console.log(this.$route.params);
  if (this.$route.params.status==='loginFailed'){
    console.log('login failed');
    this.$refs.alert.alertNow('error',json['invalid login']);
  }
},
  methods: {
    sendOtp: function (a) {
      this.details = {
        phone: a.values.phone.value,
      };
      let jsonDetails = JSON.stringify(this.details);
      fetch(`${urlOfRootApi}/login/sendOtp.php`, {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `details=${jsonDetails}`,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          alert (data.pass);
          console.log(data);
          if (data.hasOwnProperty("error")) {
            if (data.error == "The phone number you entered is not exist") {
              this.$refs.alert.alertNow("error", this.notExistPhone);
            }
          } else if (data.hasOwnProperty("pass")) this.sentPassword = true;
        })
        .catch((error) => {
          console.log(error);
          this.$refs.alert.alertNow("error", error);
        });
    },
    checkOtp: function () {
      this.loading = true;
      let details = {
        phone: this.details.phone,
        pass: this.OTPpassword,
      };
      details = JSON.stringify(details);
      fetch(`${urlOfRootApi}/login/checkOtp.php`, {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `details=${details}`,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.loading = false;
          if (data.hasOwnProperty("error")) {
            let errorMessage = json[data.error];
            this.$refs.alert.alertNow("error", errorMessage);
          }
          else{
            if (data.hasOwnProperty('succes')){
              this.$refs.alert.alertNow('success',json['login succes']);
              localStorage.setItem( 'jwt', data.token);
              let pageToGo;
              if(data.userType==='administraitor'){
                  pageToGo='/CompaniesList';                
              }
              else if (data.userType==='client manager'){
                pageToGo='/clientManagerMenu';
              }
              else if(data.userType==='company manager'){
                pageToGo='/companyManagerMenu';
              }
              else if(data.userType==='company user'){
                pageToGo='/companyUserMenu';
              }
              this.$router.push(pageToGo);
            }
          }
        })
        .catch((error) => {
          this.loading = false;
         this.$refs.alert.alertNow("error", error);
        });
    },
  },
};
</script>
