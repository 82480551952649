<template>
  <div v-if="dataTransferred">
    <v-checkbox color="indigo" hide-details :label="selectAllLabel" @click="selectAll" v-model="selectAllValue">
    </v-checkbox>
    <v-checkbox v-for="value in values" :key="value.id" :id="value.id" color="indigo" :label="value.label"
      v-model="checkBoxesValues[value.id]" hide-details />
  </div>
</template>
<script>
import {createObjectFieldInTable} from "@/data/functions.js";
import { createUpdateObject } from "@/data/functions.js";
import Vue from "vue";
import myjson from "../data/he.js";
import { bus } from "../main.js";
let json = JSON.parse(myjson);
export default {
  props: {
    values: null,
    checkBoxesName: String,
    defaultValues: { default: null },
  },
  data() {
    return {
      selectAllLabel: json["select all"],
      checkBoxesValues: {},
      selectAllValue: false,
      updateObject: {}
    };
  },
  computed: {
    allValues: function () {
      for (let value in this.checkBoxesValues) {
        if (!this.checkBoxesValues[value]) {
          return false;
        }
      }
      return true;
    },
    formType: function () {
      if (this.defaultValues === null) {
        return "insert";
      } else return "update";
    },
    dataTransferred: function () {
      if (this.formType === "insert") return this.values != null;
      if (this.formType === "update")
        return this.values != null && this.defaultValues != null;
      return false;
    },
  },
  watch: {
    allValues(val) {
      if (!val) {
        this.selectAllValue = false;
      }
    },
    values() {
      if (Object.keys(this.checkBoxesValues).length === 0) {
        this.addDefaultValuesToCheckBoxes();
      }
    },
  },
  created: function () {
    this.addDefaultValuesToCheckBoxes();
    bus.$on("saveclicked", this.save);
  },
  methods: {
    createObjectFieldInTable,
    addDefaultValuesToCheckBoxes: function () {
      if (this.defaultValues === null) {
        this.formType === "insert";
        for (let b in this.values) {
          Vue.set(this.checkBoxesValues, this.values[b].id, false);
        }
      } else {
        this.formType === "update";
        for (let b in this.values) {
          Vue.set(
            this.checkBoxesValues,
            this.values[b].id,
            this.defaultValues[this.values[b].id]
          );
        }
      }
    },
    selectAll: function () {
      console.log("select all");
      for (let a in this.checkBoxesValues) {
        this.checkBoxesValues[a] = this.selectAllValue;
      }
    },
    save: function () {
      let valuesObject=[];
      if (this.formType === "insert"){
for (let i in this.checkBoxesValues){
  valuesObject[i]= new createObjectFieldInTable(i,this.checkBoxesValues[i],['bool','required']);
}
        this.$emit(
          "checkBoxesExported", {
          name:
            this.checkBoxesName,
          values:
            valuesObject
        }
        );
      }
      else {
        if (JSON.stringify(this.defaultValues) === JSON.stringify(this.checkBoxesValues)) {
         this.$emit("checkBoxesExported", {name: this.checkBoxesName, values:null});
        }
        else {
          this.updateObject = createUpdateObject(this.checkBoxesValues, this.defaultValues);
          for(let i in this.updateObject){
            valuesObject[i]=new createObjectFieldInTable(i,this.updateObject[i],['bool','required']);
          }
          this.$emit("checkBoxesExported", {name:this.checkBoxesName, values:valuesObject});
        }
      }
    },
  },
};
</script>