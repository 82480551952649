<template>
    <div>
        <mySnackBar ref="alert"></mySnackBar>
        <table-of-data v-if="items!=null" :message="message" :columns="headers" :data="items"></table-of-data>
        <button @click="creatNewFolder">new folder</button>
        <upload-button></upload-button>
    </div>
</template>
<script>
import { newFolder } from "@/contact-js/newFolder";
import { pathOfFiles } from "@/data/const.js"
import { selectFiles } from "@/contact-js/selectFiles.js"
import myjson from "@/data/he.js"
let json = JSON.parse(myjson);
import tableOfData from '@/components/tableOfData.vue';
import { createHeadresOfTableObject } from "@/data/functions.js";
import mySnackBar from "@/components/mySnackBar.vue"
import UploadButton from '@/components/uploadButton.vue';
export default {
    data() {
        return {
            path: pathOfFiles,
            message: json['folders'],
            headers: [
                new this.createHeadresOfTableObject('name', json['folder name']),
                new this.createHeadresOfTableObject('date', json['date created'])
            ],
            items: null
        }
    },
    components: {
        tableOfData,
        mySnackBar,
        UploadButton,
    },
    created() {
        this.selectFilesFromServer()
    },
    methods: {
        newFolder,
        creatNewFolder() {
            newFolder(this.path,'frumi');
        },
        createHeadresOfTableObject,
        async selectFilesFromServer() {
            console.log(this.path);
            let data = await selectFiles(this.path);
            if (Object.prototype.hasOwnProperty.call(data, 'success')){
                this.items = data.success;
            }
            else 
            {
                this.$refs.alert.alertNow('error', json['error']);
            }

        }
    }
}
</script>
