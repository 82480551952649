<template>
  <v-list-item style="max-height: 100px;" class="overflow-y-auto mt-2">
    <v-list-item-content>
      <v-list-item-title v-for="item in list" :key="item">{{
        item
      }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  props: ["panelHeader", "list"],
};
</script>

