<template>
  <v-row>
    <v-col cols="4">
      <v-card elevation="0">

        <v-card-text class="my-0 py-0">
          <div>
            {{ viewDate }}
          </div>
          <v-divider></v-divider>
          <div class="mx-auto">
            <v-checkbox v-model="isAviable" color="indigo" class="mx-0 py-0" :label="checkBoxLable" />
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { createObjectFieldInTable } from "@/data/functions.js"
import myjson from "../data/he.js";
let json = JSON.parse(myjson);
import { bus } from "../main.js";
export default {
  props: {
    defaultValue: {
      default: null,
    },
  },
  data() {
    return {
      viewDate: `${new Date().getUTCDate()}/${new Date().getUTCMonth() + 1
        }/${new Date().getUTCFullYear()}`,
      checkBoxLable: json["isAviable"],
      dateValue: new Date(),
      values: {},
      isAviable: true,
    };
  },
  watch: {
    defaultValue: function (val) {
      if (val != null) {
        console.log(this.defaultValue);
        this.isAviable = val;
      } else {
        this.isAviable = true;
      }
    },
  },
  created() {
    //Set initial value of isAviable
    if (this.defaultValue != null) {
      this.isAviable = this.defaultValue;
    }
    bus.$on("saveclicked", this.save);
  },
  methods: {
    createObjectFieldInTable,
    save() {
      let values = {};
      if (this.defaultValue != null) {
        if (this.defaultValue === this.isAviable) {
          this.$emit("exportIsAviable", null, "update");
        }
        else{
          values['date'] = new createObjectFieldInTable('date', this.dateValue, ['date', 'required']);
          values['isAviable'] =new  createObjectFieldInTable('isAviable', this.isAviable, ['bool', 'required']);
          this.$emit("exportIsAviable", values, "update");
        }
      } else {
        values['date'] = new createObjectFieldInTable('date', this.dateValue, ['date', 'required']);
        values['isAviable'] =new  createObjectFieldInTable('isAviable', this.isAviable, ['bool', 'required']);
        this.$emit("exportIsAviable", values, "insert");
      }
    },
  },
};
</script>