import { urlOfRootApi } from "@/data/const";

/* eslint-disable */
export const update =  async function (table, where, values,t) {
    // values = JSON.stringify(values);
    let jsonObj={};
    jsonObj.ab=table;
    jsonObj.he=where;
    jsonObj.al=values;
    jsonObj.t=t;
    jsonObj=JSON.stringify(jsonObj);
    console.log(jsonObj);
   const res = await fetch(`${urlOfRootApi}/DBConnection/update.php`, {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body:`details=${jsonObj}`,
    })
    if(!res.ok){
        return {error: res.status}
    }
    else {
        let data=await res.json();
        console.log(data);
        if (data.hasOwnProperty('error')){
            if (typeof(data.error)==='object'){
                if(data.error.hasOwnProperty('errorInfo')){
                    if(data.error.errorInfo.indexOf(1062)!=-1){
                        return {'error': 'duplicate error'};
                    }
                }
            }
            else {
                if (data.error ==='duplicate phone') {
                    return {error: 'duplicate error'};
                }
            }
        }
        return data;
    }
}
