<template>
    <div>
        <tool-bar :items="companyManagerMenu"></tool-bar>
        <viewDataInnerJoin :addMessage="addMessage" :message="message" :columns="columns" table1="cl"
            tableInnerJoin="cl-m" :columnsToSelect="columnsToSelect" :onToSelect="onToSelect" add="addClient"
            name="clients" editPage="EditClient"></viewDataInnerJoin>
    </div>
</template>
<script>
import { companyManagerMenu } from "@/data/menus.js";
import toolBar from "@/components/toolBar.vue";
import { AllowAccesToThisPage } from "@/data/functions.js";
import myjson from "../data/he.js"
let json = JSON.parse(myjson);
import viewDataInnerJoin from "../components/viewDataInnerJoin.vue"
export default {
    components: {
        viewDataInnerJoin,
        toolBar
    },
    created() {
        AllowAccesToThisPage(this.$route.name);
    },
    data() {
        return {
            companyManagerMenu: companyManagerMenu,
            addMessage: json['add client'],
            message: json['clients'],
            columns: [
                {
                    value: "name",
                    text: json['client name'],
                },
                {
                    value: "company_number",
                    text: json['company number'],
                    width: '150px'
                },
                {
                    value: "phone1",
                    text: `${json['phone']} 1`
                },
                {
                    value: "phone2",
                    text: `${json['phone']} 2`
                },
                {
                    value: "address",
                    text: json['adress']
                },
                {
                    value: 'client_remarks',
                    text: json['remarks']
                },
                {
                    value: 'isAviable',
                    text: json['aviable'],
                    width: '80px'
                },
                {
                    value: "manager_name",
                    text: json['manager name']
                },
                {
                    value: "phone",
                    text: json['phone']
                },
                {
                    value: "manager_remarks",
                    text: json['remarks']
                }
            ],
            columnsToSelect: 'name,id,company_number,phone1,phone2,address,client_remarks,isAviable,manager_name,Phone',
            onToSelect: 'managerPhone=phone'
        }
    }
}
</script>

