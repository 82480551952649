<template>
  <div>
  <tool-bar :items="toolBarItems"></tool-bar>
    <mySnackBar ref="alert"></mySnackBar>
    <tableOfData v-if="itemesReady" :add="add" :addMessage="addMessage" :columns="columns" :editPage="editPage"
      :message="message" :checkBoxes="checkBoxes" name="users" :data="itemes" :lists="['companies']">
    </tableOfData>
  </div>
</template>
<script>
/* eslint-disable */
import {companyManagerMenu} from "@/data/menus.js"
import { AllowAccesToThisPage } from "@/data/functions.js";
import mySnackBar from "@/components/mySnackBar.vue";
import { bus } from "@/main.js";
import myjson from "../data/he.js";
let json = JSON.parse(myjson);
import tableOfData from "@/components/tableOfData.vue";
import { selectFromDB } from "@/contact-js/select.js";
import { selectInnerJoin } from "@/contact-js/selectInnerJoin.js";
import Vue from "vue";
import toolBar from '@/components/toolBar.vue';
export default {
  components: {
    tableOfData,
    mySnackBar,
    toolBar
  },
  data() {
    return {
      //bool to check if the itemes ready
      itemesReady: false,

      toolBarItems: companyManagerMenu,
      //the items for the table
      itemes: [],
      checkBoxes: ["isAviable", "open_folders", "edit_rename_folders", "edit_clients"],
      addMessage: json["add user"],
      message: json["users"],
      editPage: "editUser",
      add: "/addUser",
      userData: {},
      companiesToUser: {},
      columns: [
        {
          value: "firstname",
          text: json["first name"],
        },
        {
          value: "lastname",
          text: `${json["last name"]}`,
        },
        {
          value: "phone",
          text: json["phone"],
        },
        {
          value: "isAviable",
          text: json["status"],
        },
        {
          value: "remarks",
          text: json["remarks"],
        },
        {
          value: "open_folders",
          text: json["open folders"],
        },
        {
          value: "edit_rename_folders",
          text: json["edit/rename folders"],
        },
        {
          value: "edit_clients",
          text: json["edit clients"],
        },
        {
          value: "companies",
          text: json['companies'],
          width: '200px'

        }
      ],
    };
  },
  created() {
    AllowAccesToThisPage(this.$route.name);
    this.selectDataFromDB();
  },
  methods: {
    selectDataFromDB: async function () {
      let data = await selectFromDB('cm-u', '*', '1', localStorage.jwt);
      console.log(data);
      if (data.hasOwnProperty('error')) {
        this.errorHandlingDatabaseErrors(data);
      }
      else if (data.hasOwnProperty('success')) {
        this.userData =JSON.parse(data.success);
        data = await selectInnerJoin(
          "cm-t-u",
          "cm",
          "user,company,company_name",
          "companies.id=company",
          "companiesToUsers",
          localStorage.jwt
        );
        if (data.hasOwnProperty('error')) {
          this.errorHandlingDatabaseErrors(data);
        }
        else if (data.hasOwnProperty('success')) {
          this.companiesToUser = data.success;
          this.createObjectForTable();
        }
        else {
          this.$refs.alert.alertNow('error', data);
        }
      }
      else this.$refs.alert.alertNow('error', data);
    },
  errorHandlingDatabaseErrors: function (data) {
    if (data.error === 'Wrong number of segments') {
      this.$router.push('/login/loginFailed');
    }
    else {
      this.$refs.alert.alertNow('error', data.error);
    }
  },
  createObjectForTable: function () {
    if (this.userData==='null'){
      this.itemes=null;}
      else{

    this.itemes = this.userData;
    for (let i in this.itemes) {
      Vue.set(this.itemes[i], "companies", []);
      Vue.set(this.itemes[i], "companiesDetailes", []);
    }
    for (let i in this.companiesToUser) {
      for (let j in this.itemes) {
        if (this.itemes[j].phone == this.companiesToUser[i].user) {
          let item = {
            id: this.companiesToUser[i].company,
            company_name: this.companiesToUser[i].company_name,
          };
          this.itemes[j].companiesDetailes.push(item);
          this.itemes[j].companies.push(item.company_name);
        }
      }
    }
    this.$dataFromServer['users'] = this.itemes;
      }
    this.itemesReady = true;
  },
  }
}
  ;
</script>

