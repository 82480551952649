import myjson from "@/data/he.js"
let json = JSON.parse(myjson);
export const companyManagerMenu =
   [
        new createMenuObj(json["users"], "UsersList"),
        new createMenuObj(json["clients"], "ClientsList"),
        new createMenuObj(json['add user'],"addUser"),
        new createMenuObj(json['add client'],"addClient")
      ];
export function createMenuObj (title, link) {
    this.title = title;
    this.link = link;
  }
  export const administraitorMenu = [
    new createMenuObj(json['companies'],'CompaniesList'),
    new createMenuObj(json['add company'], 'addCompany')

  ]