var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('mySnackBar',{ref:"alert"}),_c('v-data-table',{staticClass:"elevation-3 px-6 py-3",attrs:{"headers":_vm.columns,"items":_vm.dataToView,"items-per-page":10,"sort-by":_vm.columns[0].value,"header-props":{
      sortIcon: 'mdi-sort-descending',
    }},scopedSlots:_vm._u([_vm._l((_vm.checkBoxes),function(checkBox){return {key:("item." + checkBox),fn:function(ref){
    var item = ref.item;
return [_c('v-simple-checkbox',{key:checkBox,attrs:{"disabled":""},model:{value:(item[checkBox]),callback:function ($$v) {_vm.$set(item, checkBox, $$v)},expression:"item[checkBox]"}})]}}}),_vm._l((_vm.lists),function(list){return {key:("item." + list),fn:function(ref){
    var item = ref.item;
return [_c('listInTable',{key:list,attrs:{"list":item[list],"panelHeader":_vm.companyPermissions}})]}}}),{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.message))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.add!='')?_c('router-link',{staticStyle:{"display":"inline-block","text-decoration":"none"},attrs:{"to":_vm.add}},[_c('v-btn',{staticClass:"mx-2",attrs:{"rounded":"","dark":"","color":"indigo  darken-4","large":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1):_vm._e(),(_vm.dialogFields.length>0)?_c('v-dialog'):_vm._e()],1)]},proxy:true},(_vm.editPage != '')?{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{staticStyle:{"display":"inline-block","text-decoration":"none"},attrs:{"to":("/" + _vm.editPage + "/" + (_vm.findKey(item)))}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }