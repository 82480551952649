<template>
    <v-file-input hide-input v-model="file"></v-file-input>   
</template>
<script>
export default {
    data (){
        return {file: ""
        }
    },
}
</script>
