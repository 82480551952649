/* eslint-disable */
import { deletFromDB } from './delet.js';
import { insertToDB } from './insert.js';
import { urlOfRootApi } from '@/data/const.js';
import { createObjectFieldInTable } from '@/data/functions.js';

export async function insertInterrelatedData(table1, table2, values1, values2, forgienKey, primary, t) {
    let jsonObj = {};
    jsonObj.ab1 = table1;
    jsonObj.ab2 = table2;
    jsonObj.al1 = values1;
    jsonObj.al2 = values2;
    jsonObj.or = forgienKey;
    jsonObj.ri=primary;
    jsonObj.t=t;
    jsonObj=JSON.stringify(jsonObj);
    //לבדוק אם צריך להמיר שוב את vlaues ל json.
    const res = await fetch(`${urlOfRootApi}/DBConnection/insertInterrelatedData.php`, {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `details=${jsonObj}`
    })
    if (!res.ok) {
        return { error: res.status };
    }
    else {
        let data = await res.json();
        console.log(data);
        if (data.hasOwnProperty('success')) {
            let insertInput2Result = await insertToDB(table2, values2, t);
            console.log(insertInput2Result);
            if (insertInput2Result.hasOwnProperty('error')) {
                if(insertInput2Result.error==='duplicate phone')  {
                    return {error : 'duplicate error'};
                }
                return 'error to insert input2';
            }
            else if (insertInput2Result.hasOwnProperty('success')) {
                // add the manger data to input1 data
                // search the primary key in values1
                let primaryValue;
                let primaryRules;
                for (let i in values2) {
                    if (values2[i].name === primary) {
                        primaryValue = values2[i].value;
                        primaryRules = values2[i].rules;
                    }
                }
                values1.push(new createObjectFieldInTable(forgienKey, primaryValue, primaryRules));
                console.log(values1);
                let insertInput1Result = await insertToDB(table1, values1, t);
                console.log(insertInput1Result);
                if (insertInput1Result.hasOwnProperty('error')) {
                    //delete input2 from db 
                    return { error: 'errorto insert input1' };
                }
                else if (insertInput1Result.hasOwnProperty('success')) {
                    return { success: 'data inserted succes' };
                }
                else {
                    return { error: insertInput1Result };
                }
            }

        }
        else {
            return {
                error: 'duplicate error',
                duplicatesColumns: data
            };
        }

    }
}