import { urlOfRootApi } from "@/data/const"; 
export const deletFromDB = async function (table, where, t) {
    let jsonObj={};
    jsonObj.ab=table;
    jsonObj.he=where;
    jsonObj.t=t;
    jsonObj=JSON.stringify(jsonObj);
    const res = await fetch(`${urlOfRootApi}/DBConnection/delete.php`, {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `details=${jsonObj}`,
    });
    if (!res.ok){
        return {error: res.status};
    }
    else {
        let data = await res.json();
        console.log(data);
        return data;
    }
}
