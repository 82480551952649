<template>
  <div>
    <editTwoInputs :menu="administraitorMenu" :input1="companyInputs" :input2="companyManagerInputs" formName="companies" :title="title"></editTwoInputs>
  </div>
</template>
<script>
import { administraitorMenu } from "@/data/menus.js"
import { AllowAccesToThisPage } from "@/data/functions.js"
import editTwoInputs from "@/components/editTwoInputs.vue"
import { companyInputs } from "@/data/inputsDetails.js"
import { companyManagerInputs } from "@/data/inputsDetails.js"
import myjson from "../data/he.js"
let json = JSON.parse(myjson);
export default {
  components: {
    editTwoInputs,
  },
  data() {
    return {
      companyInputs,
      companyManagerInputs,
      title: json['edit copmany'],
      administraitorMenu
    }
  },
  created(){
    console.log(this.$route.name);
    AllowAccesToThisPage(this.$route.name);
  }
}
</script>

