export default JSON.stringify({
    "add company":"הוסף חברה",
    "company name":"שם החברה",
    "phone":"טלפון",
    "company number": "ח.פ. / עוסק מורשה",
    "adress":"כתובת",
    "credit card": "פרטי כרטיס אשראי",
    "remarks": "הערות",
    "isAviable":"פעיל / לא פעיל",
    "full name":"שם פרטי ומשפחה",
    "tz":"תעודת זהות",
    "mail":"מייל",
    "copmany manager details":"פרטי מנהל חברה",
    "save":"שמור",
    "add/edit user":"הוסף / ערוך משתמש",
    "first name":"שם פרטי",
    "last name":"שם משפחה",
    "open folders":"פתיחת תיקים",
    "edit/rename folders": "מחיקת תיקים/שינוי שם",
    "edit clients": "עריכת לקוחות",
    "company permissions":"הרשאות לחברה",
    "add client":"הוסף לקוח",
    "client name":"שם לקוח",
    "select all":"בחר הכל",
    "permissions for company":"הרשאות לחברה",
    "manager details":"פרטי מנהל",
    "save and continue to open folders": "שמור והמשך לפתיחת תיקים",
    "not valid phone":"מספר טלפון לא תקין",
    "not valid company number":"מספר ח.פ. לא תקין",
    "not valid name":"שם לא תקין",
    "required":"זהו שדה חובה",
    "error insert data":"בעיה בקליטת נתונם נסה שוב",
    "succes insert data": "נתונים נקלטו בהצלחה",
    "duplicalte company number error":"מספר הח.פ. שהזנת כבר קיים במערכת",
    "duplicate phone error":"מספר הטלפון שהזנת כבר קיים במערכת",
    "not valid tz":"מספר תעודת זהות שגוי",
    'not valid email':'כתובת מייל לא תקינה',
    "duplicate error":"הנתונים שהזנת כבר קיימים במערכת",
    "add / edit users":"הוסף / ערוך משתמשים",
    "add user":"הוסף משתמש",
    "status":"סטאטוס",
    "aviable" : "פעיל",
    "not aviable" : "לא פעיל",
    "add / edit company" : "הוסף / ערוך חברה",
    "manager name":"שם מנהל",
    "email":"מייל",
    "duplicate manager error":"נתוני המנהל שהזנת כבר קיימים במערכת",
    "add / edit client":"הוסף / ערוך לקוחות",
    "no changes":"לא נעשו שינויים",
    "edit":"ערוך",
    "Data updated":"נתונים התעדכנו",
    'clients': 'לקוחות',
    'client details':'פרטי לקוח' ,
    "edit client":"ערוך לקוח",
    "No changes were made":"לא נעשו שינויים",
    "cancel":"ביטול",
    "company detailes":"פרטי חברה",
    "edit copmany":"ערוך חברה",
    "companies":"חברות",
    "users":"משתמשים",
    "enter":"כניסת משתמש",
    "send password":"שלח ססמא",
    "Enter the one-time password sent to you":"הכנס את הססמא החד פעמית שנשלחה אליך",
    "ok":"אישור",
    "The phone number you entered is not exist":"מספר הטלפון שהזנת אינו קיים",
    "Incorrect OTP":"קוד לא נכון",
    "Too many tries for OTP":"עברת את מספר הנסיונות המותר",
    "OTP has expired":"תוקף הססמא פג",
    "login succes":"נכנסת בהצלחה",
    "add/edit clients":"הוסף / ערוך לקוחות",
    "log out":"יציאה",
    "menu":"תפריט",
    "invalid login":"כניסה לא תקינה יש להתחבר מחדש",
    "folders": "תיקים",
    "folder name":"שם תיקיה",
    "date created":"תאריך יצירה"

    



})