<template>
  <div>
    <twoInputsForm :input1="input1" :input2="input2" :isAviable="isAviableValue" formType="update" :title="title" :menu="menu"></twoInputsForm>
    </div>
</template>
<script>
import Vue from "vue"
// import toolBar from "@/components/toolBar.vue";
import twoInputsForm from "../components/twoInputsForm.vue";
export default {
  components: {
    twoInputsForm,
    // toolBar
  },
  props: ['input1', 'input2', 'formName','title','menu'],
  data() {
    return {
      details: this.$dataFromServer[this.formName][this.$route.params.id],
    }
  },
  computed: {
    isAviableValue: function () {
      if (this.details.isAviable === "1"||this.details.isAviable===1)
        return true;
      else
        return false;
    }

  },
  mounted: function () {
    /*insert the details  to object.
    the key is the name of the coloumn ib DB */
    for (let d in this.input1.values) {
      let key = this.input1.values[d].id;
      Vue.set(this.input1.defaultValues, key, this.details[key]);
    }
    //add the id to the object
    Vue.set(this.input1.defaultValues, 'id', this.details.id);
    /*insert the values of the input2 to object.
the key is the name of the coloumn ib DB */
    for (let d in this.input2.values) {
      let key = this.input2.values[d].id;

      Vue.set(this.input2.defaultValues, key, this.details[key]);
    }
  }
}
</script>

