import Vue from "vue";
import router from "@/router";
import myjson from "./he.js";
let json = JSON.parse(myjson);

import { AllowAccesToPage } from "@/contact-js/allowAccesPage";
// import myJson from "./he.js"
// export const alertSucces = function(){
//     if (this.$route.params.action == "inserted") {
//         bus.$emit("alert", "success", this.success);
//         // setTimeout(this.removeParamrsFromUrl(), 10000);
//       } else if (this.$route.params.action == "updated") {
//         bus.$emit("alert", "success", json["Data updated"]);
//         // setTimeout(this.removeParamrsFromUrl(), 10000);
//       }
// };
// export const removeParamrsFromUrl=function() {
//     this.$router.push(`/${this.$router.currentRoute.name}`);
//   };
export const createUpdateObject = function (values,defaultValues) {
  let updateObject = {};
  for (let i in values) {
      if (values[i] !=defaultValues[i]) {
          updateObject[i] = values[i];
      }
  }
  return updateObject;
};
export const createHeadresOfTableObject= function (value, text) {
  this.value=value;
  this.text=text;
}
//function that get object from the form and return array to insert to db
export const arrayToInsertToDB= function(values){
  console.log('creat object fo DB');
  let valuesToDB=[];
  let index=0;
  for (let i in values){
      valuesToDB[index]=values[i];
      index++;
  }
  return valuesToDB;
}
export const addIsAviableToValues =  function (isAviableValues,values) {
  if (isAviableValues != null) {
    if (values === null) {
      values = {};
    }
    if (Object.prototype.hasOwnProperty.call(values, 'date'))
      values.date = isAviableValues.date;
      values.isAviable = isAviableValues.isAviable;
    } else {
      Vue.set(values, "date", isAviableValues.date);
      Vue.set(values, "isAviable", isAviableValues.isAviable);
    }
    return values;
  };
  export  async function AllowAccesToThisPage(routeNAme) {
    let pages = await AllowAccesToPage(localStorage.jwt);
    console.log(pages);
    console.log(routeNAme);
    if (Object.prototype.hasOwnProperty.call(pages, "error")) {
      router.push("/login/loginFailed");
    } else if (Object.prototype.hasOwnProperty.call(pages, "pages")) {
      if (!pages.pages.includes(routeNAme)) {
        router.push("/login/loginFailed");
      }
    } else {
      router.push("/login/loginFailed");
    }
  }
  export const errorHandlingDatabaseErrors =  function (data) {
    console.log(data);
    if (data.error === 'Wrong number of segments') {
      this.$router.push('/login/loginFailed');
    }
    else {
      if(data.error==='duplicate phone'){
        this.$refs.alert.alertNow('error',json['duplicate error']);
      }
      else {
      this.$refs.alert.alertNow('error', data.error);
    }
  }
}
   //function that create object to insert to DB
  // the object has the keys: name, value, rules (array of rules names)
  export const createObjectFieldInTable =  function (myname,value,rules){
    this.name=myname;
    this.value=value;
    this.rules=rules; 
  }