<template>
  <v-toolbar dense color="indigo darken-4">
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
    <template v-slot:extension>
      <v-tabs  align-with-title class="write--text">
        <v-tab class="white--text" v-for="item in items" :key="item.title" :to="`/${item.link}`">{{item.title}}</v-tab>
         <v-tabs-slider color="white"></v-tabs-slider>
      </v-tabs> 
          <v-spacer></v-spacer>
    <v-icon color="white" @click="logOut">mdi-logout</v-icon>  
    </template>
    <!-- <v-menu>

      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon v-bind="attrs" v-on="on" color="white"></v-app-bar-nav-icon>
      </template>
      <v-list v-if="items">
        <v-list-item v-for="(item, i) in items" :key="i" link>
          <v-list-item-title >{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->

  </v-toolbar>
</template>
  <script>
  export default {
    components: {
    },
    props: ['items'],
    methods: {
      logOut: function () {
        localStorage.removeItem('jwt');
        this.$router.push('/');
      }
    }
  };
  </script>
