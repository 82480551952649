 <template>
  <v-snackbar
    v-model="visible"
    auto-height
    :color="color"
    :multi-line="mode === 'multi-line'"
    :timeout="timeout"
    top
  >
    <v-layout  align-center pr-4>
      <v-icon class="pr-3" dark large>{{ icon }}</v-icon>
      <v-layout column>
        <div v-if="title" class="text-center">
          <strong> {{ title }}</strong>
        </div>
        <div class="text-center">{{ text }}</div>
      </v-layout>
    </v-layout>
    <v-btn v-if="timeout === 0" icon @click="visible = false">
      <v-icon>clear</v-icon>
    </v-btn>
  </v-snackbar>
</template>
<script>
export default {
  // props:['color','mode','title','text','timeout'],
  data() {
    return {
      visible: false,
      color: "",
      mode: "",
      title: "",
      text: "",
      timeout: "",
      icon: "",
    };
  },
  methods: {
    alertNow: function (type,text) {
      this.color = type;
      this.text = text;
      if (type === "success") {
        this.icon = "mdi-check-circle-outline";
      } else {
        this.icon = "mdi-alert-circle-outline";
      }
      // this.title=title;
      this.timeout=3000;
      this.visible = true;
    },
  },
};
</script>
