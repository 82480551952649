<template>
  <v-form v-model="isValid" lazy-validation ref="form">
    <div v-for="input in inputsValue" :key="input.key">
      <v-text-field
        :label="input.placeHolder"
        hide-details="auto"
        reverse
        :rules="input.rule"
        :class="{
          'mx-0': $vuetify.breakpoint.smAndDown,
          'mx-10': $vuetify.breakpoint.mdAndUp,
        }"
        v-model="values[input.id]"
      ></v-text-field>
    </div>
  </v-form>
</template>
<script>
/*eslint-disable*/
import {createObjectFieldInTable} from "@/data/functions.js";
import { createUpdateObject } from "@/data/functions.js";
import Vue from "vue";
import { bus } from "../main.js";
export default {
  props: {
    formType: {
      type: String,
      default: "insert",
    },
    inputsValue: Array,
    inputsName: String,
    defaultValues: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      //information for the html
      myInputsValues: this.inputsValue,
      //the data from inputs to server
      values: [],
      // Object with the modified data only
      updateObject: {},
      isValid: true,
      isChanged: false,
    };
  },
  watch: {
    defaultValues: {
      immediate: true,
      deep: true,
      handler: function (val) {
        if (this.formType === "insert") {
          this.createValues();
        } else this.values = Object.assign({}, val);
      },
    },
  },
  created: function () {
    //add  bool property to myInputsValues for validation to inputs
    for (let v in this.myInputsValues) {
      Vue.set(this.myInputsValues[v], "isValid", true);
    }
    bus.$on("saveclicked", this.save);
  },
  methods: {
    //function to save only modifeid data in object
    // createUpdateObject: function () {
    //     this.updateObject = {};
    //     for (let i in this.values) {
    //         if (this.values[i] != this.defaultValues[i]) {
    //             this.updateObject[i] = this.values[i];
    //         }
    //     }
    // },
    createObjectFieldInTable,
    createValues: function () {
      this.values = [];
      for (let v in this.myInputsValues) {
        let key = this.myInputsValues[v].id;
        Vue.set(this.values, key, "");
      }
      this.isChanged = false;
      // return myValues;
    },
    createValuesObjectWhithRule(values) {
        let valuesObjectWhithRules=[];
        for (let i in this.myInputsValues){
          let name = this.myInputsValues[i].id;
          if (values.hasOwnProperty(name)){
           valuesObjectWhithRules[name]=new this.createObjectFieldInTable(name,values[name],this.inputsValue[i].valid);
          }
        }
        console.log(valuesObjectWhithRules);
        return valuesObjectWhithRules;
    },
        //function that create object to insert to DB
        // // the object has the keys: name, value, rules (array of rules names)
        // createObjectFieldInTable(myname,value,rules){
        //   this.name=myname;
        //   this.value=value;
        //   this.rules=rules; 
        // },
    //function to valid the data fro  inputs and sent them to the parent form.
    save: function () {
      if (this.$refs.form.validate()) {
        if (this.formType === "insert") {
          let values = this.createValuesObjectWhithRule(this.values);
          this.$emit("exportValuesInputs", {
            name: this.inputsName,
            values: values,
            type: "insert",
          });
        } else {
          //check if the data updated
          if (
            JSON.stringify(this.defaultValues) === JSON.stringify(this.values)
          ) {
            this.$emit("exportValuesInputs", {
              name: this.inputsName,
              values: null,
            });
          } else {
            //create new object only fron the new data
            this.updateObject = createUpdateObject(
              this.values,
              this.defaultValues
            );
            let updateObjectWhithRules = this.createValuesObjectWhithRule(this.updateObject);

            this.$emit("exportValuesInputs", {
              name: this.inputsName,
              values: updateObjectWhithRules,
            });
          }
        }
      }
    },
    //     validation:function(){
    //         console.log('valid form');
    //      let isAllValid = true;
    //         let i = 0;
    //         for (let value in this.values) {
    //             let isValid = true;
    //             if (i < this.myInputsValues.length) {
    //                 if (this.myInputsValues[i].valid != "no") {
    //                     if (this.myInputsValues[i].valid == "validEmail") {
    //                         isValid = validEmail(this.values[value]);
    //                     }
    //                     else if (this.myInputsValues[i].valid == "validPhone") {
    //                         isValid = validPhone(this.values[value]);
    //                     }
    //                     else if (this.myInputsValues[i].valid == "validMobilePhone") {
    //                         isValid = validMobilePhone(this.values[value]);
    //                     }
    //                     else if (this.myInputsValues[i].valid == "validNumbers") {
    //                         isValid = validNumbers(this.values[value]);
    //                     }
    //                     else if (this.myInputsValues[i].valid == "validNorequiredPhone") {
    //                         isValid = validNorequiredPhone(this.values[value]);
    //                     }
    //                     else if (this.myInputsValues[i].valid == "validOnlyLetters") {
    //                         isValid = validOnlyLetters(this.values[value]);
    //                     }
    //                     else if (this.myInputsValues[i].valid == "required") {
    //                         isValid = required(this.values[value]);
    //                     }
    //                     isAllValid = isAllValid && isValid;
    //                     this.myInputsValues[i].isValid = isValid;
    //                 }
    //                 i++;
    //             }
    //         }
    //         return isAllValid;
    //     }
  },
};
</script>