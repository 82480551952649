<template>
  <div dir="rtl">
  <tool-bar :items="menu"></tool-bar>
    <v-row class="d-flex justify-center mx-6 py-5">
      <v-col xs="12" md="8" lg="6" class="elevation-3 text-center">
        <MySnackBar ref="alert"></MySnackBar>
        <myH1 :message="title" class="pt-5"></myH1>
        <v-divider class="pb-5"></v-divider>
        <h2 class="text-center">{{ input1.message }}</h2>
        <form fef="form">
          <myInputs :inputs-name="input1.name" :inputs-value="input1.values"
            :defaultValues="input1.defaultValues || undefiened" :formType="formType || undefiened" class="pt-5"
            @exportValuesInputs="valuesExports"></myInputs>
          <v-spacer></v-spacer>
          <isAviable class="mt-5" :defaultValue="isAviable" @exportIsAviable="isAviableExported"></isAviable>
          <h2 class="text-center my-3">{{ input2.message }}</h2>
          <myInputs :inputs-name="input2.name" :inputs-value="input2.values"
            :defaultValues="input2.defaultValues || undefiened" :formType="formType || undefiened"
            @exportValuesInputs="valuesExports"></myInputs>
          <v-row>
            <v-col class="text-center">
              <saveButton class="my-3 mx-2" :message="saveMessage"></saveButton>

              <router-link :to="input1.homePage" style="display: inline-block; text-decoration: none">
                <v-btn outlined rounded large color="indigo" class="my-3">{{
                    cancel
                }}</v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import { arrayToInsertToDB } from "@/data/functions.js";
import { update } from "@/contact-js/update.js";
import { insertInterrelatedData } from "@/contact-js/insertInterrelatedData.js";
import myH1 from "@/components/myH1.vue";
import myInputs from "./myInputs.vue";
import isAviable from "./isAviable.vue";
import saveButton from "./saveButton.vue";
import { bus } from "../main.js";
import myjson from "../data/he.js";
import mySnackBar from "@/components/mySnackBar.vue";
import MySnackBar from "@/components/mySnackBar.vue";
import ToolBar from './toolBar.vue';
let json = JSON.parse(myjson);
export default {
  components: {
    myH1,
    myInputs,
    isAviable,
    saveButton,
    mySnackBar,
    MySnackBar,
    ToolBar,
  },
  props: {
    title: String,
    input1: Object,
    input2: Object,
    formType: String,
    menu: Array,
    isAviable: {
      type: Boolean,
      default: null,
    },
    saveMessage: {
      default: json["save"],
      type: String,
    },
  },
  data() {
    return {
      input1Values: {},
      input2Values: {},
      //when all this 3 variable is true the data sent to server
      input1ValuesSaved: false,
      input2ValuesSaved: false,
      isAviabledSaved: false,
      //for update form if all this variable is false There is no change in the data
      input1Changed: false,
      input2Changed: false,
      isAviableChanged: false,
      //for update form for check if the update success
      input1Updated: false,
      input2Updated: false,

      updateFailed: false,

      //for insert form for check if insert success
      input1Inserted: false,
      input2Inserted: false,

      noChanges: json["No changes were made"],
      error: json["error insert data"],
      success: json["succes insert data"],
      cancel: json["cancel"],
    };
  },
  computed: {
    isAllInsertTrue: function () {
      return (
        this.isAviabledSaved && this.input1ValuesSaved && this.input2ValuesSaved
      );
    },

  },
  watch: {
    isAllInsertTrue(val) {
      if (val) {
        this.insertDataToDB();
      }
    },

  },
  methods: {
    arrayToInsertToDB,
    insertDataToDB: async function () {
      let result;
      let values1 = arrayToInsertToDB(this.input1Values);
      let values2 = arrayToInsertToDB(this.input2Values);
     
      //insert form
      if (this.formType === "insert") {
        result = await insertInterrelatedData(
          this.input1.table,
          this.input2.table,
          values1,
          values2,
          this.input1.forgienKey,
          this.input2.primary,
          localStorage.jwt
        );

      }
      //update form 
      //check for changes
      else {
        if (
          this.input1Changed === false &&
          this.input2Changed === false &&
          this.isAviableChanged === false
        ) {
          this.$refs.alert.alertNow("warning", this.noChanges);
          this.input1ValuesSaved = false;
          this.input2ValuesSaved = false;
          this.isAviabledSaved = false;
        }
        //change only input1 details
        else {
          if (this.input1Changed || this.isAviableChanged) {
            let updateValues = { ...this.input1Values, ...this.isAviable };
            updateValues= arrayToInsertToDB(updateValues);
            let where = `id=${this.input1.defaultValues.id}`;
            console.log(where);
            console.log(updateValues);
            result = await update(this.input1.table, where, updateValues, localStorage.jwt);
            console.log(result);
            this.input1ValuesSaved = false;
          }
          //change input2 detailes
          if (this.input2Changed) {
            let where = `${this.input2.primary}=${this.input2.defaultValues[this.input2.primary]
              }`;
            let input2ToDB=arrayToInsertToDB(this.input2Values);
            result = await update(this.input2.table, where, input2ToDB, localStorage.jwt);
            console.log(result)
          }
        }
      }
      //Notice to user whether the data entry was successful
      // this.input2Changed = false;{}
      if (result.hasOwnProperty("error")) {
        this.input1ValuesSaved = false;
        this.input2ValuesSaved = false;
        this.isAviabledSaved = false;
        //duplicate error 
        if (result.error === 'duplicate error') {
          this.$refs.alert.alertNow('error', json['duplicate error']);
        }
        else {
          this.input1ValuesSaved = false;
          this.input2ValuesSaved = false;
          this.isAviabledSaved = false;
          this.$refs.alert.alertNow("error", this.error);
        }
      } else {
        if (result.hasOwnProperty("success")) {
          if (this.formType === 'insert') {
            this.$router.push(`/${this.input1.homePage}/inserted`);
          }
          else {
            console.log('go to table page');
            this.$router.push(`/${this.input1.homePage}/updated`);
          }
        } else {
          this.input1ValuesSaved = false;
          this.input2ValuesSaved = false;
          this.isAviabledSaved = false;
          this.$refs.alert.alertNow("error", result);
        }
      }
    },
    valuesExports: function (eventValues) {
      if (this.formType === 'insert') {
        if (eventValues.name === this.input2.name) {
          this.input2Values = eventValues.values;
          this.input2ValuesSaved = true;
        }
        if (eventValues.name === this.input1.name) {
          this.input1Values = { ...eventValues.values };
          this.input1ValuesSaved = true;
        }
      }
      //update form
      if (this.formType === "update") {
        //check for changes
        if (eventValues.values != null) {
          if (eventValues.name === this.input1.name) {
            this.input1Updated = false;
            this.input1Changed = true;
            this.input1ValuesSaved = true;
            this.input1Values = eventValues.values;
          } else if (eventValues.name === this.input2.name) {
            this.input2Changed = true;
            this.input2ValuesSaved = true;
            this.input2Updated = false;
            this.input2Values = eventValues.values;
          }
        } else {
          if (eventValues.name === this.input1.name) {
            this.input1Changed = false;
            this.input1ValuesSaved = true;
          } else if (eventValues.name === this.input2.name) {
            this.input2Changed = false;
            this.input2ValuesSaved = true;
          }
        }
      }
    },
    isAviableExported(values) {
      this.isAviabledSaved = true;
      //update form
      if (this.formType === "update") {
        if (values != null) {
          this.isAviableChanged = true;
          this.input1Changed = true;
          this.input1Updated = false;
          this.addIsAviableToValues(values);
        }
      }
      //insert form
      else {
        this.addIsAviableToValues(values);
      }
    },
    // dataUpdate: function (data, inputName) {
    //   if (data == "1") {
    //     if (inputName == this.input1.name) {
    //       this.input1Updated = true;
    //       // this.input1Changed = false;
    //       // this.isAviableChanged = false;
    //     }
    //     if (inputName == this.input2.name) {
    //       this.input2Updated = true;
    //       // this.input2Changed = false;
    //     }
    //   } else {
    //     this.updateFailed = true;
    //   }
    // },

    addIsAviableToValues: function (values) {
      if (values != null) {
        if (this.input1Values === null) {
          this.input1Values = {};
        }
        if (this.input1Values.hasOwnProperty("date")) {
          this.input1Values.date = values.date;
          this.input1Values.isAviable = values.isAviable;
        } else {
          Vue.set(this.input1Values, "date", values.date);
          Vue.set(this.input1Values, "isAviable", values.isAviable);
        }
      }
    },
  },
};
</script>