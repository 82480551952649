import { urlOfRootApi } from '@/data/const.js';
export const selectFromDB = async function (table, columns, where, t) {
    let jsonObj = {};
    jsonObj.ab = table;
    jsonObj.ol = columns;
    jsonObj.he = where;
    jsonObj.t = t;
    jsonObj=JSON.stringify(jsonObj);
    const res = await fetch(`${urlOfRootApi}/DBConnection/select.php`, {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `details=${jsonObj}`,
    })
    if (!res.ok) {
        return { error: res.status };
    }
    else {
        let data = await res.json();
        console.log(data);
        return data;
    }
}
