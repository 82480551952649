import { urlOfRootApi } from "@/data/const";
export async function AllowAccesToPage(t){
   const res= await fetch(`${urlOfRootApi}/login/allowedAccessPage.php`, {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `t=${t}`,
    })
    if(!res.ok){
        return {error: res.status}
    }
    else{
    let data=await res.json();
    return data;
    }
//    if(!res.ok){
//     console.log(res.status);
//    }   
//    else{
//     console.log(typeof(res));
//    }
}