import { urlOfRootApi } from "@/data/const";
export async function selectFiles(path) {
    let jsonObj = {
        at: path,
        t: localStorage.jwt
    }
    jsonObj = JSON.stringify(jsonObj);
    const res = await fetch(`${urlOfRootApi}/upload/selectFiles.php`, {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `details=${jsonObj}`,
    });
    if (!res.ok) {
        console.log(`error: ${res.status}`);
        return { error: res.status };
    }
    else {
        let data = await res.json();
        if (Object.prototype.hasOwnProperty.call(data, 'success')){
               let files=JSON.parse(data.success);
              files.splice(0,2);
            data.success=files;
            }
                return data;
    }
}