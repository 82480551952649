<template>
  <div>
    <tool-bar class="mb-2"></tool-bar>
    <menu-page :items="items"></menu-page>
  </div>
</template>
<script>
import toolBar from "@/components/toolBar.vue";
import { AllowAccesToThisPage } from "@/data/functions.js";
import menuPage from "@/components/menuPage.vue";
import myjson from "../data/he.js";
let json = JSON.parse(myjson);
export default {
  components: { menuPage, toolBar },
  data() {
    return {
      items: [
        new this.creatItemObj(json["add/edit user"], "UsersList"),
        new this.creatItemObj(json["add/edit clients"], "ClientsList"),
      ],
    };
  },
  created() {
    AllowAccesToThisPage(this.$route.name);
  },
  methods: {
    creatItemObj: function (title, link) {
      this.title = title;
      this.link = link;
    },
  },
};
</script>
