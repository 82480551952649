<template>
  <div>
    <twoInputsForm :menu="administraitorMenu" :title="title" :input1="companyInputs" :input2="companyManagerInputs" formType="insert"></twoInputsForm>
  </div>
</template>
<script>
import { administraitorMenu } from "@/data/menus";
import { AllowAccesToThisPage } from "@/data/functions";
import {companyInputs} from "@/data/inputsDetails.js"
import {companyManagerInputs} from "@/data/inputsDetails.js"
import TwoInputsForm from '../components/twoInputsForm.vue';
import myjson from "../data/he.js"
// import ToolBar from '@/components/toolBar.vue';
let json = JSON.parse(myjson);
export default {
  components: {
    TwoInputsForm,
    // ToolBar
  },
  created(){
    AllowAccesToThisPage(this.$route.name);
  },
  data() {
    return {
      administraitorMenu,
            companyInputs,
companyManagerInputs,
title: json['add company']
    }
  }
}

</script>

