/* eslint-disable */
import { urlOfRootApi } from "@/data/const.js"
export  async function selectInnerJoin (table ,innerJoinTable, columns, on, name, t) {
    let jsonObj={};
    jsonObj.ab=table;
    jsonObj.inab=innerJoinTable;
    jsonObj.ol=columns;
    jsonObj.on=on;
    jsonObj.t=t;
    jsonObj=JSON.stringify(jsonObj);
            const res = await fetch(`${urlOfRootApi}DBConnection/selectInnerJoin.php`, {
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: `details=${jsonObj}`,
            })
                if(!res.ok){
                    return {error: res.status}
                }
                else {
                    let data=await res.json();
                    console.log(data);
                    return data;
                }
        }
