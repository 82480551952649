import { urlOfRootApi } from "@/data/const";
export async function newFolder(path, name){
    let jsonObj = {
        at: path,
        am: name,
        t: localStorage.jwt
    }
    jsonObj = JSON.stringify(jsonObj);
    const res =  await fetch(`${urlOfRootApi}/upload/newFolder.php`, {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `details=${jsonObj}`,
    }
    );
    if (!res.ok){
        console.log(res.status);
    }    
    else {
        let data = await res.json();
        console.log(data);
    }
}